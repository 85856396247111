

.accordin {
  margin-bottom: 20px;
}
*{
  font-family: 'Open Sans', sans-serif;
}

.h1_title {
  width: 90;
  text-align: center;
  background-color: #a03c64;
  padding: 10px 5px;
  border-radius: 50px;
  color:white;
  font-weight: bold;
}
.table {
  width: 100% !important;
  font-weight: bold;
}

.bold-red-header{
  /* i want bold  */
  font-weight: bold;

  font:bolder;
}

.ftop {
  display: flex;
  width: 100%;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  padding-bottom: 10px;
  border-radius: 5px 5px 0px 0px;
  padding: 15px 0px;
}

.fbuttondiv {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-left: 10px;
}

.fbuttondiv_div{
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}
.fbutton {
  border: none;
  background: none;
  color: #000;
  font-size: 14px;
  margin-right: 20px;
  margin-top: 5px;
  cursor: pointer;
}

.fbutton span {
   color: #a03c64;
   margin-Left: 3px;
}
.filterdiv1 {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}

.selectdiv {
  border: none;
  background: none;
  color: #000;
  font-size: 14px;
  margin-right: 20px;
  margin-top: 5px;
  /* cursor: pointer; */
}

.inputfield {
  border: none;
  background: none;
  color: #000 !important;
  font-size: 14px;
  margin-right: 20px;
  margin-top: 5px;
  cursor: pointer;
  border-bottom: 1px solid #000;
  outline: none;
}

.filterbtn {
  /* border: 1px solid #a5305f; */
  font-size: 15px;
  margin-right: 20px;
  margin-top: 5px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  padding: 5px 10px;
  color :white;
  background-color: #a5305f;
}

.filterbtn:active,
.filterbtn:hover,
.filterbtn:focus{
  box-shadow: 0 0 0 0.2rem rgba(168, 33, 87, 0.25);
  background-color: #a03c64;
  color: white;
  border-color: #a03c64; 
}

.pagination {
  display: flex;
  justify-content: flex-end;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  border-radius: 5px 5px 0px 0px;
  padding: 20px 0px 10px;
}

.pagecount{
  color: "#fff";
  font-size: 14px;
  margin-right: 13px;
  margin-top: 0px;
}
.pagecount span {
  color: black;
}

.customColumnHeader {
  padding: 10px;  
}


.rowperpage {
  font-size: 16px;
  margin-top: 0px;
  color: black;
  margin-right: 15px;
}

.rowperselect {
  border: none;
  background: none;
  color: black;
  font-size: 14px;
  margin-right: 30px;
  margin-top: 0px;
  cursor: pointer;
}


/* Style for the "Go to First Page" and "Go to Last Page" buttons */
.pagination-button {
  border: none;
  background: none;
  color: #000; /* Button text color */
  cursor: pointer;
  margin-top: 10px;
  z-index: 100;
  padding-right: 28px; /* Add padding to the right to create space */
}

.pagination-button[disabled] {
  color: #ccc; /* Change text color when disabled */
}

/* Optional: Add space between the buttons */
.pagination-button + .pagination-button {
  margin-left: 10px;
}




.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height if necessary */
}

.custom-spinner {
  color: #a03c64 !important;
  font-size: 3em; 
  width: 2em !important;
  height: 2em !important; 

}



.filledCircle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: blue;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-Weight: "bold",
}


.CenteredContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ModalContent {
  width: 550px;
  height: 550px;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 24px;
  padding: 20px;
  position: relative;
}