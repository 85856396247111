.selector {
    text-align: left;
    padding-top: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.tab__component {
    display: grid;
    place-items: center;
}

.navbar {
    background-color: #a03c64;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

#logout {
    color: white;
    font-weight: bold;
    font-size: 21px;

}

#submit_button {
    background-color: #a03c64;
    color: white;
    font-weight: bold;
    font-size: 21px;
    width: 275px;
}

.form_input {
    opacity: 0.85;
    border-radius: 3px;

}

.form_input:active,
.form_input:focus {
    border-color: #a03c64 !important;
    border-radius: 10px !important;
    box-shadow: 0 0 0 0.2rem rgba(160, 60, 100, 0.25) !important
}

.logout{
    color:white;
    font-weight: bold;

    border-radius: 2px solod white;

}

.logout:hover{
    background-color: #a03c64;
    color:white;
}